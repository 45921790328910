
































































































































































import Vue from 'vue';
import moment from 'moment';
import { message, Modal } from 'ant-design-vue';
import * as couponApi from '@/api/couponTemplate';
import createCoupon from './createCoupon/index.vue';
import couponDetail from './couponDetail/index.vue';
export default Vue.extend({
  name: 'couponTemplateList',
  components: { createCoupon, couponDetail },
  data() {
    return {
      tableLoading: false,
      searchForm: {
        name: '', // 优惠券名称
        status: '', // 优惠券状态
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      couponTemplateColumns: [
        {
          title: '优惠券名称',
          dataIndex: 'name',
          align: 'center',
          fixed: 'left',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '优惠券编号',
          dataIndex: 'couponDefinitionCode',
          align: 'center',
        },
        {
          title: '有效期',
          dataIndex: 'expiredType',
          align: 'center',
          scopedSlots: { customRender: 'expiredType' },
        },
        // {
        //   title: '使用范围',
        //   dataIndex: 'availableAppStr',
        //   align: 'center',
        // },
        {
          title: '触发条件',
          dataIndex: 'eventConfigName',
          align: 'center',
        },
        {
          title: '满减(元)/折扣',
          dataIndex: 'strategyStr',
          align: 'center',
        },
        {
          title: '派发总数',
          dataIndex: 'totalMaxObtainTimes',
          align: 'center',
          scopedSlots: { customRender: 'totalMaxObtainTimes' },
        },
        {
          title: '领取次数',
          dataIndex: 'peerMaxObtainTimesStr',
          align: 'center',
        },
        {
          title: '最大使用次数',
          dataIndex: 'useMaxCountPerAgency',
          scopedSlots: { customRender: 'useMaxCountPerAgency' },
          align: 'center',
        },
        {
          title: '使用条件',
          dataIndex: 'useConditionStr',
          align: 'center',
        },
        {
          title: '创建人',
          dataIndex: 'recUserName',
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'recDate',
          align: 'center',
          scopedSlots: { customRender: 'recDate' },
        },
        {
          title: '优惠券状态',
          dataIndex: 'couponDefinitionStatus',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'couponDefinitionStatus' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      couponTemplateData: [] as any,
      applicationList: [], //应用下拉列表数据
      eventList: [], //事件下拉列表数据
    };
  },
  mounted() {
    this.getCouponTemplateList();
    // this.getApplicationList();
    this.getSysEventList('Coupon');
  },
  methods: {
    moment,
    //获取应用列表
    getApplicationList() {
      couponApi.getApplicationList(1).then((res) => {
        if (res.status === 200) {
          this.applicationList = res.result;
        } else {
          message.error(res.message);
        }
      });
    },
    //获取事件列表
    getSysEventList(eventType) {
      couponApi.getSysEventList(eventType).then((res) => {
        if (res.status === 200) {
          this.eventList = res.result;
        } else {
          message.error(res.message);
        }
      });
    },
    // 获取优惠券模板列表
    getCouponTemplateList() {
      this.tableLoading = true;
      couponApi
        .getCouponTemplateList(
          this.searchForm.name,
          this.searchForm.status,
          this.pagination.current,
          this.pagination.pageSize
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.couponTemplateData = res.result.list;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getCouponTemplateList();
    },
    // 组合查询表单重置
    handleSearchReset() {
      (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.pagination.current = 1;
      this.getCouponTemplateList();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getCouponTemplateList();
    },
    // 新建线下活动按钮事件
    handleCreate() {
      (this.$refs.createCoupon as HTMLFormElement).show();
    },
    // 编辑事件
    handleEdit(record) {
      (this.$refs.createCoupon as HTMLFormElement).edit(record);
    },
    //下架事件
    handleOff(record) {
      Modal.confirm({
        title: '操作提示',
        content: '确定下架吗？',
        okType: 'danger',
        okText: '是的，确定下架！',
        onOk: () => {
          return new Promise((resolve) => {
            couponApi
              .offCouponRecords(record.sequenceNbr)
              .then((res) => {
                if (res.status === 200) {
                  message.success('操作成功！');
                } else {
                  message.error('操作失败！');
                }
              })
              .finally(() => {
                resolve(true);
                this.handleSearchSubmit();
              });
          });
        },
      });
    },
    // 作废事件
    handleCancel(record) {
      Modal.confirm({
        title: '操作提示',
        content: '确定作废吗？',
        okType: 'danger',
        okText: '是的，确定作废！',
        onOk: () => {
          return new Promise((resolve) => {
            couponApi
              .discardCouponRecords(record.sequenceNbr)
              .then((res) => {
                if (res.status === 200) {
                  message.success('操作成功！');
                } else {
                  message.error('操作失败！');
                }
              })
              .finally(() => {
                resolve(true);
                this.handleSearchSubmit();
              });
          });
        },
      });
    },
    // 发布事件
    handleRelease(record) {
      Modal.confirm({
        title: '操作提示',
        content: '确定发布吗？',
        okText: '是的，确定发布！',
        onOk: () => {
          return new Promise((resolve) => {
            couponApi
              .releaseCouponRecords(record.sequenceNbr)
              .then((res) => {
                if (res.status === 200) {
                  message.success('操作成功！');
                } else {
                  message.error('操作失败！');
                }
              })
              .finally(() => {
                resolve(true);
                this.handleSearchSubmit();
              });
          });
        },
      });
    },
    // 详情事件
    handleDetail(record) {
      (this.$refs.couponDetail as HTMLFormElement).show(record.sequenceNbr);
    },
    // 数据保存或发布成功事件回调
    formSaveSuccess() {
      this.handleSearchSubmit();
    },
  },
});
